<!-- ----------------------------------------------------------------------- -->
<!--
name    : CLIENT JOB LIST

type    : component

used by : AppClientView

uses    : table-status

route   : none
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <v-container id="jobsList" class="pa-4 client-job-list-component">
    <div v-if="jobs && jobs.length > 0" class="mt-2">
      <v-data-table
        id="jobsTable"
        :headers="headers"
        :items="jobs"
        class="elevation-1">
        <template v-slot:item="props">
          <tr @click="$router.push('/jobs/view/' + props.item.uuid)">
            <td>JOB-{{ props.item.number.toString().padStart(5, '0') }}</td>
            <td>{{ jobsiteName(props.item) }}</td>
            <td v-if="props.item.workorders">
              {{ props.item.workorders.length }}
            </td>
            <td v-else>Not Applicable</td>
            <td class="text-center">
              <table-status :status="props.item.phase" />
            </td>
          </tr>
        </template>
      </v-data-table>
    </div>
    <div v-if="jobs && jobs.length == 0" class="mt-2">
      <v-card>
        <v-card-text class="title"> None Available </v-card-text>
      </v-card>
    </div>
  </v-container>
</template>

<script>
  import TableStatus from '@/components/table-status';
  import Jobs from '@/services/Jobs.service.js';

  export default {
    name: 'clientJobList',
    components: {
      'table-status': TableStatus,
    },
    props: {
      client: Object,
      job_sites: Array,
    },
    data() {
      return {
        headers: [
          {
            text: 'Job ID',
            align: 'left',
            sortable: true,
            value: 'number',
          },
          {
            text: 'Job Site Name',
            value: 'jobsite.name',
          },
          {
            text: 'No. Work Orders',
            value: 'workorders.length',
          },
          {
            text: 'Phase',
            value: 'phase',
            align: 'center',
          },
        ],
        jobs: [],
      };
    },
    watch: {
      job_sites() {
        this.associateJobSites();
      },
    },
    async mounted() {
      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();
      var res = await Jobs.getJobs(
        {
          client_uuid: this.client.uuid,
          tenant_uuid: this.$auth.userProfile.tenant_uuid,
        },
        accessToken
      );

      if (res) {
        this.jobs = res;
        this.associateJobSites();
      }
    },
    methods: {
      associateJobSites() {
        // attatch jobsites to jobs
        for (let job of this.jobs) {
          for (let jobsite of this.job_sites) {
            if (job.serviceRequest) {
              if (job.serviceRequest.job_site_uuid == jobsite.uuid) {
                job.jobsite = jobsite;
              }
            } else {
              console.log("couldn't get jobsite uuid in client-job-list.vue");
              console.log('job service request: ', job.serviceRequest);
            }
          }
        }
        this.jobs = JSON.parse(JSON.stringify(this.jobs));
      },
      jobsiteName: function (job) {
        return job.jobsite && job.jobsite.name
          ? job.jobsite.name
          : 'No Site Name';
      },
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .clientList {
    text-align: left;
    width: 40%;
    margin: 0 auto;
  }
</style>
