<!-- ----------------------------------------------------------------------- -->
<!--
name    : APP CLIENT VIEW

type    : view

uses    : header-view
          main-container
          main-card
          inventory-map
          client-job-list
          table-status

route   : /clients/view/:uuid
 -->
<!-- ----------------------------------------------------------------------- -->
<template>
  <div class="app-client-view" v-if="client">
    <header-view
      :title="clientID + ': ' + client.client_name"
      :previous_page="'/clients'"
      :edit_url="'/clients/edit/' + client.uuid" />
    <main-container>
      <main-card :min_height="188">
        <v-row>
          <v-col cols="12" sm="6">
            <v-divider v-if="$vuetify.breakpoint.xsOnly" />
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="3">
                <b>Client Name</b>
              </v-col>
              <v-col>
                {{ client.client_name }}
              </v-col>
            </v-row>
            <v-divider v-if="$vuetify.breakpoint.xsOnly" />
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="3">
                <b>Contact Name</b>
              </v-col>
              <v-col>
                {{ client.contact_name }}
              </v-col>
            </v-row>
            <v-divider v-if="$vuetify.breakpoint.xsOnly" />
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="3">
                <b>Contact Phone</b>
              </v-col>
              <v-col>
                {{ client.contact_phone }}
              </v-col>
            </v-row>
            <v-divider v-if="$vuetify.breakpoint.xsOnly" />
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="3">
                <b>Contact Email</b>
              </v-col>
              <v-col>
                {{ client.contact_email }}
              </v-col>
            </v-row>
          </v-col>
          <v-divider :vertical="!$vuetify.breakpoint.xsOnly" />
          <v-col>
            <v-divider v-if="$vuetify.breakpoint.xsOnly" />
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="3">
                <b>Address</b>
              </v-col>
              <v-col>
                <div>{{ client.address }}</div>
                <div>
                  {{ client.city }}, {{ client.state }} {{ client.zip_code }}
                </div>
                <div>{{ client.country }}</div>
              </v-col>
            </v-row>
            <v-divider v-if="$vuetify.breakpoint.xsOnly" />
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="3">
                <b>Client Type</b>
              </v-col>
              <v-col>
                {{ client.client_type }}
              </v-col>
            </v-row>
            <v-divider v-if="$vuetify.breakpoint.xsOnly" />
            <v-row dense>
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="3">
                <b>Client Status</b>
              </v-col>
              <v-col>
                {{ client.client_status }}
              </v-col>
            </v-row>
            <!-- left in for debugging quickbooks functionality -->
            <!-- <v-divider v-if="$vuetify.breakpoint.xsOnly" />
            <v-row v-if="quickbooksEnabled" dense>
   
              <v-col class="text-left text-sm-right" cols="12" sm="6" md="3">
                <b>Quickbooks</b>
              </v-col>
              <v-col>
                <v-btn x-small @click="uploadClientToQuickbooks">
                  Add Client to Quickbooks
                </v-btn>
                <span v-if="client.quickbooks_id">synced</span>
              </v-col>
            </v-row>
             -->
          </v-col>
        </v-row>
      </main-card>
      <main-card>
        <v-tabs v-model="active" class="ma-2" slider-color="primary">
          <v-tab key="jobs" ripple> JOBS </v-tab>
          <v-tab key="service_requests" ripple> SERVICE REQUESTS </v-tab>
          <v-tab key="estimates_tab" ripple> ESTIMATES </v-tab>
          <v-tab key="invoices_tab" ripple> INVOICES </v-tab>
          <v-tab key="job_sites_tab" href="#jobSites" ripple> JOB SITES </v-tab>
        </v-tabs>
        <v-tabs-items v-model="active" touchless>
          <v-tab-item key="jobs">
            <client-job-list
              v-if="client && client.uuid"
              :client="client"
              :job_sites="jobSites" />
          </v-tab-item>

          <v-tab-item key="service_requests">
            <v-container id="serviceRequestTableContainer">
              <div>
                <div class="float-right">
                  <router-link
                    v-if="active == 1"
                    :to="{
                      name: 'ClientServiceRequestCreate',
                      params: {uuid: client.uuid},
                    }">
                    <v-btn class="button-primary px-2 mr-3">
                      New Service Request
                    </v-btn>
                  </router-link>
                </div>
                <br />
                <br />
              </div>
              <div
                v-if="serviceRequests && serviceRequests.length > 0"
                class="mt-2">
                <v-data-table
                  id="serviceRequestTable"
                  :headers="serviceRequestHeaders"
                  :items="serviceRequests"
                  class="elevation-1">
                  <template v-slot:item="props">
                    <tr
                      @click="
                        $router.push({
                          name: 'ServiceRequestView',
                          params: {uuid: props.item.uuid},
                        })
                      ">
                      <td>
                        SR-{{ props.item.number.toString().padStart(5, '0') }}
                      </td>
                      <td>{{ props.item.requestor_name }}</td>
                      <td>
                        {{
                          displayDateFormat(
                            props.item.date_submitted,
                            tenantSettings.date_format
                          )
                        }}
                      </td>
                      <td class="text-center">
                        <table-status
                          :status="props.item.service_request_status" />
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
              <div
                v-if="serviceRequests && serviceRequests.length == 0"
                class="mt-2">
                <v-card>
                  <v-card-text class="title"> None Available </v-card-text>
                </v-card>
              </div>
            </v-container>
          </v-tab-item>
          <v-tab-item key="estimates_tab">
            <v-container id="estimatesTableContainer">
              <div v-if="estimates && estimates.length > 0" class="mt-2">
                <v-data-table
                  v-if="this.showEstimateTable"
                  id="estimateTable"
                  :headers="estimateHeaders"
                  :items="estimates"
                  class="elevation-1">
                  <template v-slot:item="props">
                    <tr
                      @click="
                        $router.push({
                          name: 'EstimateView',
                          params: {uuid: props.item.uuid},
                        })
                      ">
                      <td>
                        EST-{{ props.item.number.toString().padStart(5, '0') }}
                      </td>
                      <td>{{ props.item.estimator_name || 'Unassigned' }}</td>
                      <td>
                        {{
                          displayDateFormat(
                            props.item.due_date,
                            tenantSettings.date_format
                          ) || 'Unassigned'
                        }}
                      </td>
                      <td>
                        {{
                          formatCurrency(
                            props.item.total_price,
                            currencySymbol
                          ) || 'Unassigned'
                        }}
                      </td>
                      <td class="text-center">
                        <table-status :status="props.item.status" />
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
              <div v-if="estimates && estimates.length == 0" class="mt-2">
                <v-card>
                  <v-card-text class="title"> None Available </v-card-text>
                </v-card>
              </div>
            </v-container>
          </v-tab-item>
          <v-tab-item key="invoices_tab">
            <v-container id="estimatesTableContainer">
              <div
                v-if="client && invoices && invoices.length > 0"
                class="mt-2">
                <v-data-table
                  id="invoiceTable"
                  :headers="invoiceHeaders"
                  :items="invoices"
                  class="elevation-1">
                  <template v-slot:item="props">
                    <tr
                      @click="
                        $router.push({
                          name: 'InvoiceView',
                          params: {uuid: props.item.uuid},
                        })
                      ">
                      <td>
                        INV-{{ props.item.number.toString().padStart(5, '0') }}
                      </td>
                      <td>
                        {{ formatCurrency(props.item.amount, currencySymbol) }}
                      </td>
                      <td>
                        {{ formatCurrency(props.item.balance, currencySymbol) }}
                      </td>
                      <td class="text-center">
                        <table-status :status="props.item.status" />
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
              <div v-if="invoices && invoices.length == 0" class="mt-2">
                <v-card>
                  <v-card-text class="title"> None Available </v-card-text>
                </v-card>
              </div>
            </v-container>
          </v-tab-item>
          <v-tab-item key="job_sites_tab" value="jobSites">
            <v-container fluid>
              <div>
                <div class="float-right">
                  <v-btn
                    :to="'/clients/view/' + client.uuid + '/newJobSite'"
                    class="button-primary px-2">
                    New Job Site
                  </v-btn>
                </div>
                <br />
                <br />
              </div>
              <div v-if="jobSites && jobSites.length > 0">
                <v-layout row>
                  <v-flex sm4 pr-4>
                    <v-toolbar flat color="rgba(255, 0, 0, 0)">
                      <v-row v-if="!jobSiteTreeToggle">
                        <v-toolbar-title class="headline">
                          Job Sites
                        </v-toolbar-title>
                        <v-spacer />
                      </v-row>
                      <v-toolbar-title v-else class="headline">
                        Trees
                        <v-btn
                          class="ma-1 button-secondary"
                          @click="viewAllJobSites">
                          All Job Sites
                        </v-btn>
                      </v-toolbar-title>
                    </v-toolbar>
                    <v-divider />
                    <v-list
                      v-if="!jobSiteTreeToggle"
                      key="jobSites"
                      style="
                        background: transparent;
                        max-height: 80vh;
                        overflow: auto;
                      ">
                      <v-list-item v-for="item in jobSites" :key="item.uuid">
                        <v-list-item-content>
                          <v-card class="mx-auto" @click="jobSiteClicked(item)">
                            <v-card-title>
                              {{ item.name || 'Name Unavailable' }}
                              <v-spacer />
                              <router-link
                                :to="{
                                  name: 'JobSiteEdit',
                                  params: {uuid: item.uuid},
                                }">
                                <v-icon class="px-2"> mdi-pencil </v-icon>
                              </router-link>
                            </v-card-title>
                            <v-card-text>
                              <div><b>Address:</b> {{ item.address }}</div>
                              <div>
                                {{ item.city }}, {{ item.state }}
                                {{ item.country }}
                              </div>
                              <div>
                                <b>Active Jobs:</b> {{ item.numActiveJobs }}
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-btn text @click="viewJobSite(item)">
                                View Job Site
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-list
                      v-else
                      style="
                        background: transparent;
                        max-height: 80vh;
                        overflow: auto;
                      ">
                      <v-list-item
                        v-for="item in filteredTrees"
                        :key="item.uuid">
                        <v-list-item-content>
                          <v-card class="mx-auto" @click="treeClicked(item)">
                            <v-card-title v-if="item.species">
                              {{ item.species.common_name || 'Not Available' }}
                            </v-card-title>
                            <v-card-text>
                              <div><b>DBH: </b>{{ item.dbh }}</div>
                              <div><b>Height: </b>{{ item.height }}</div>
                              <div>
                                <b>Notes: </b
                                >{{ item.notes || 'None available.' }}
                              </div>
                            </v-card-text>
                            <v-card-actions />
                          </v-card>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-flex>
                  <v-flex sm8>
                    <inventory-map
                      ref="inventoryMap"
                      v-bind:tenantSettings="tenantSettings"
                      v-bind:jobsites="jobSites"
                      v-bind:selected_jobsite_uuid="selectedJobSiteUuid"
                      :can_add_trees="false"
                      v-bind:trees="filteredTrees"
                      v-bind:inventory_type="mapLayer" />
                  </v-flex>
                </v-layout>
              </div>

              <!-- This shows if there are no job sites -->
              <div v-if="jobSites && jobSites.length == 0" class="mt-2">
                <v-card>
                  <v-card-text class="title"> None Available </v-card-text>
                </v-card>
              </div>
            </v-container>
          </v-tab-item>
        </v-tabs-items>
      </main-card>
    </main-container>

    <!-- left in for debugging quickbooks functionality -->
    <!--
    <v-snackbar v-model="snackbar" :timeout="5000">
      {{ snackbarText }}
      <v-btn color="blue" text @click="snackbar = false"> Close </v-btn>
    </v-snackbar>

     <v-dialog v-model="errorDialog" persistent max-width="500">
      <v-card>
        <v-card-title class="headline">
          {{ errorHeader }}
        </v-card-title>
        <v-card-text>{{ errorBody }}</v-card-text>
        <v-form v-if="qbDupNameChange" ref="clientNameUpdateForm">
          <v-text-field
            id="newClientName"
            v-model="newClientName"
            label="New Client Name"
            :rules="validate_new_client_name" />
        </v-form>
        <v-card-actions>
          <v-spacer />
          <v-btn
            v-if="!qbDupNameChange"
            color="green darken-1"
            text
            @click="
              () => {
                this.errorDialog = false;
              }
            ">
            Close
          </v-btn>
          <v-btn
            v-if="qbDupNameChange"
            color="green darken-1"
            text
            @click="updateClientName()"
            :loading="loading">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog> -->
  </div>
</template>

<script>
  // components
  import InventoryMap from '@/components/inventory-map';
  import clientJobList from '@/components/clients/client-job-list';
  import HeaderView from '@/components/header-view';
  import MainContainer from '@/components/main-container';
  import MainCard from '@/components/main-card';
  import TableStatus from '@/components/table-status';

  // services
  import Clients from '@/services/Clients.service.js';
  import Jobs from '@/services/Jobs.service.js';
  import Invoices from '@/services/Invoices.service.js';
  import Tenants from '@/services/Tenants.service.js';
  import Users from '@/services/Users.service.js';

  // mixins
  import dateTimeHelpers from '@/mixins/dateTimeHelperFunctions';
  import Localization from '@/mixins/localization';
  import configurations from '@/mixins/configurations';

  export default {
    name: 'AppClientView',
    components: {
      'inventory-map': InventoryMap,
      'client-job-list': clientJobList,
      'header-view': HeaderView,
      'main-container': MainContainer,
      'main-card': MainCard,
      'table-status': TableStatus,
    },
    mixins: [dateTimeHelpers, Localization, configurations],
    props: {
      uuid: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        client: null,
        serviceRequestHeaders: [
          {
            text: 'SR ID',
            align: 'left',
            sortable: true,
            value: 'number',
          },
          {
            text: 'Contact Name',
            value: 'requestor_name',
          },
          {
            text: 'Date Submitted',
            value: 'date_submitted',
          },
          {
            text: 'Request Status',
            value: 'service_request_status',
            align: 'center',
          },
        ],
        estimateHeaders: [
          {
            text: 'Estimate ID',
            value: 'number',
          },
          {
            text: 'Estimator Name',
            align: 'left',
            sortable: true,
            value: 'estimator_name',
          },
          {
            text: 'Due Date',
            value: 'due_date',
          },
          {
            text: 'Total Price',
            value: 'total_price',
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center',
          },
        ],
        invoiceHeaders: [
          {
            text: 'Invoice ID',
            value: 'number',
          },
          {
            text: 'Amount',
            value: 'amount',
          },
          {
            text: 'Balance',
            value: 'balance',
          },
          {
            text: 'Status',
            value: 'status',
            align: 'center',
          },
        ],
        jobSitesHeaders: [
          {
            text: 'Address',
            align: 'left',
            value: 'address',
          },
          {
            text: 'City',
            value: 'city',
          },
          {
            text: 'State',
            value: 'state',
          },
          {
            text: 'Country',
            value: 'country',
          },
        ],
        serviceRequests: [],
        estimates: [],
        jobSites: [],
        selectedJobSiteUuid: '',
        filteredTrees: [],
        tableView: true,
        map: undefined,
        google: undefined,
        geocoder: undefined,
        showEstimateTable: false,
        trees: undefined,
        active: undefined,
        mapLayer: 'jobSites',
        jobSiteTreeToggle: false,
        invoices: [],
        tenantSettings: {},
        quickbooksEnabled: false,
        quickbooksConnected: false,
        snackbar: false,
        snackbarText: '',
        errorDialog: false,
        errorHeader: '',
        errorBody: '',

        // left in for debugging quickbooks functionality:
        // qbDupNameChange: false,
        // newClientName: '',
        // oldClientName: '',
        // loading: false,
        // validate_new_client_name: [
        //   (v) => !!v || 'Client Name is required',
        //   (v) => v != this.oldClientName || 'Please enter a new client name',
        // ],
      };
    },
    computed: {
      currencySymbol() {
        var symbol = this.$auth.tenantProfile.currency_symbol
          ? this.$auth.tenantProfile.currency_symbol
          : '$';
        return symbol;
      },
      switchLabel: function () {
        if (this.tableView) {
          return 'Table';
        } else {
          return 'Map';
        }
      },
      clientID: function () {
        if (this.client && this.client.hasOwnProperty('number')) {
          return 'CLIENT-' + this.client.number.toString().padStart(5, '0');
        } else {
          return 'UNKNOWN';
        }
      },
    },
    watch: {
      trees: function () {
        // console.log( 'AppClientView - trees changed' )
      },
      configuration: async function () {
        if (
          this.configuration &&
          this.configuration.quickbooks &&
          this.$auth.tenantProfile.quickbooks_enabled
        ) {
          this.quickbooksEnabled = true;
        }
      },
    },
    async created() {
      //load invoices
      if (this.$route.query && this.$route.query['tab']) {
        this.active = this.$route.query['tab'];
      }
    },
    async mounted() {
      // console.log( "AppClientView: ", this.uuid )

      if (!this.uuid) {
        console.log('AppClientView: no uuid was passed in!');
        return;
      }

      // get tenant settings
      // (in an authenticated situation we already have this info
      // via tenantProfile that gets loaded in $auth during the
      // begging of the user's session. This special case
      // is needed for the connected components that need this info
      // passed in for unsecured estimate proposal links)
      this.tenantSettings = this.$auth.tenantProfile
        ? this.$auth.tenantProfile
        : await Tenants.getSettingsInfo(
            this.$auth.userProfile.tenant_uuid
            // unsecured route
          );

      // Get the access token from the auth wrapper
      const accessToken = await this.$auth.getTokenSilently();

      // get client
      var res_client = await Clients.getClient(this.uuid, accessToken);

      if (res_client) {
        this.client = res_client;

        if (res_client.quickbooks_id) {
          this.quickbooksConnected = true;
        }

        this.invoices = await Invoices.getInvoicesByTenant(
          this.client.tenant_uuid,
          {
            client_uuid: this.client.uuid,
          },
          accessToken
        );
      }

      // get service requests
      var res_servicerequests = await Jobs.getServiceRequests(
        {
          client_uuid: this.uuid,
        },
        accessToken
      );

      if (res_servicerequests) {
        this.serviceRequests = res_servicerequests;
      }

      // get esimates
      var res_estimates = await Jobs.getEstimates(
        {
          client_uuid: this.uuid,
        },
        accessToken
      );

      if (res_estimates) {
        this.estimates = res_estimates;

        // get all users for tenant
        var users = await Users.getUsersByTenant(
          this.$auth.userProfile.tenant_uuid,
          accessToken
        );

        if (users) {
          for (let i = 0; i < users.length; i++) {
            for (let j = 0; j < this.estimates.length; j++) {
              if (this.estimates[j].estimator_uuid == users[i].uuid) {
                this.estimates[j].estimator_name = users[i].name;
              }
            }
          }
          this.showEstimateTable = true;
        }
      }

      //load client job sites

      let jobsites = await Clients.getJobSites(
        {
          tenant_uuid: this.$auth.userProfile.tenant_uuid,
          client_uuid: this.uuid,
        },
        accessToken
      );

      if (jobsites) {
        var jobSiteIds = jobsites.map((jobsite) => jobsite.uuid);

        var job_phases = await Jobs.getJobPhasesByJobSite(
          {
            tenant_uuid: this.$auth.userProfile.tenant_uuid,
            jobSiteIds: jobSiteIds,
          },
          accessToken
        );

        if (job_phases) {
          // calculate whether the job site is associated to a business or residence

          for (let i = 0; i < jobsites.length; i++) {
            var uuid = jobsites[i].uuid;

            if (job_phases[uuid]) {
              jobsites[i].jobPhase = job_phases[uuid].phase;
              jobsites[i].numActiveJobs = 0;
              jobsites[i].client_type = this.client.client_type;
              if (job_phases[uuid].jobPhases) {
                Object.values(job_phases[uuid].jobPhases).forEach(
                  (jobPhase) => {
                    if (jobPhase == 'Active') {
                      jobsites[i].numActiveJobs++;
                    }
                  }
                );
              }
            }
          }

          this.jobSites = jobsites;
        }
      }

      //load client trees

      var trees = await Clients.getTrees(
        {
          client_uuid: this.uuid,
        },
        accessToken
      );

      if (trees) {
        this.trees = trees;
        this.filteredTrees = this.trees;

        //translate species uuid
        for (let i = 0; i < this.trees.length; i++) {
          var tenant_species = await Tenants.getSingleTenantSpecies(
            this.trees[i].tenant_species_uuid,
            accessToken
          );

          if (tenant_species) {
            var masterResponse = await Tenants.getMasterSpeciesBySpeciesLookup({
              species_uuids: [tenant_species.species_uuid],
            });

            if (masterResponse && masterResponse[0]) {
              this.trees[i].species = masterResponse[0];
              this.trees[i].common_name = masterResponse[0].common_name;
            }
          }
        }
        this.filteredTrees = this.trees;

        if (this.$route.query && this.$route.query['jobSiteID']) {
          this.viewJobSite({
            uuid: this.$route.query['jobSiteID'],
          });
        }
      }
    },
    methods: {
      jobSiteClicked: function (event) {
        this.$events.$emit('jobSiteMarkerSelect', event.uuid);
      },
      viewJobSite: function (jobsite) {
        this.selectedJobSiteUuid = jobsite.uuid;
        if (!this.jobSiteTreeToggle) {
          this.filteredTrees = this.filteredTrees.filter((tree) => {
            return tree.job_site_uuid == jobsite.uuid;
          });
          this.mapLayer = 'trees';
          this.jobSiteTreeToggle = true;
        }
      },
      viewAllJobSites: function () {
        this.filteredTrees = this.trees;
        this.mapLayer = 'jobSites';
        this.jobSiteTreeToggle = false;
      },
      treeClicked: function (event) {
        this.$events.$emit('treeMarkerSelect', event.uuid);
      },

      /*
      Note: left in for debugging quickbooks functionality 

      updateClientName: async function (name) {
        console.log('name: ', name, this.newClientName);

        // validate name
        if (!this.$refs.clientNameUpdateForm.validate()) {
          return false;
        }

        // set loading spinner
        this.loading = true;

        // Get the access token from the auth wrapper
        const accessToken = await this.$auth.getTokenSilently();

        this.client.client_name = this.newClientName;

        let res = await Clients.updateClient(
          this.client.uuid,
          this.client,
          accessToken
        );

        if (res) {
          this.uploadClientToQuickbooks();
        }
      },
      uploadClientToQuickbooks: async function () {
        // Get the access token from the auth wrapper
        let accessToken = await this.$auth.getTokenSilently();

        // set qb name (for duplicate name adjustments)
        this.newClientName = this.client.client_name;
        this.oldClientName = this.client.client_name;

        // create new or sync existing quickbooks client
        const resp = await Tenants.createQuickbooksClient(
          this.client,
          accessToken
        );

        this.loading = false;
        if (resp && resp.quickbooks_id) {
          this.client.quickbooks_id = resp.quickbooks_id;
          this.snackbarText = 'Client synced with quickbooks successfully';
          this.snackbar = true;
          this.errorDialog = false;
        } else {
          this.errorHeader = 'Error';
          if (resp && resp.message) {
            this.errorBody = resp.message;

            if (resp.changeName) {
              this.qbDupNameChange = true;
            }
          } else {
            this.errorBody =
              'An error occurred while syncing this client with quickbooks - please contact support';
          }

          this.errorDialog = true;
        }
      },
      */
    },
  };
</script>

<!-- Add 'scoped' attribute to limit CSS to this component only -->
<style scoped lang="scss">
  h3 {
    margin: 40px 0 0;
  }
  ul {
    list-style-type: none;
    padding: 0;
  }
  li {
    display: inline-block;
    margin: 0 10px;
  }
  a {
    color: #42b983;
  }
  .clientList {
    text-align: left;
    width: 40%;
    margin: 0 auto;
  }
  #mapDiv {
    width: 100vw;
    height: 100vh;
  }
  .mapWrapper {
    display: flex;
  }
  .tabItems {
    background-color: transparent !important;
  }
</style>
